import React, {useEffect, useState} from 'react';
import logo_gatek from '../../Assets/Images/logo_gatek.png';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import gatek from '../../Assets/Images/_Gatek.png';
import WatchDog from '../WatchDog/WatchDog';
import ProjectInformation from './ProjectInformation';
import Team from './Team';
import Contact from './Contact';
import Timeline from './Timeline';
import pandaroux_livre from '../../Assets/Images/pandaroux_livre.webp';

const Home: React.FC = () => {
    const navigate = useNavigate();
    const [tabs, setTabs] = useState(["À Propos", "Actualités" ,"Notre Equipe", "Contact"])
    const [selectedTab, setSelectedTab] = useState(tabs[0]);
    const location = useLocation();

    const handleDownload = async () => {
        const link = document.createElement('a');
        // Chemin relatif du fichier depuis la position de ce script
        link.href = require('../../Assets/APK/GatekApp.apk');
        link.download = 'GatekApp.apk';  // Nom du fichier à sauvegarder
        document.body.appendChild(link);
        link.click();
        link.remove();
    };

    useEffect(() => {
        const path = window.location.pathname;
        if (path === '/')
            navigate("./acceuil");

        document.body.className = 'bg-black';
        return () => {
            document.body.className = '';
        };
    }, []);

    return (
        <div className='relative w-screen h-screen flex flex-col'>
            <div className='relative w-full h-[5%] bg-gatek-400 flex justify-between'>
                <div className='relative w-[10%] h-full flex items-center justify-center'>
                    <img className='relative h-full object-contain' src={logo_gatek} />
                    <p className='PermanentMarker-Regular text-gatek_gray-100 text-2xl'>GATEK</p>
                </div>
                <div className='relative w-[30%] h-full flex justify-between'>
                    <button onClick={() => {navigate("./acceuil");}} className={`w-full truncate ${location.pathname.includes("acceuil") ? " Inter-Bold" : "Inter-Regular cursor-pointer  hover:opacity-70"} text-gatek_gray-100 text-sm`}>Accueil</button>
                    <button onClick={() => {navigate("./teams");}} className={`w-full truncate ${location.pathname.includes("teams") ? " Inter-Bold" : "Inter-Regular cursor-pointer  hover:opacity-70"} text-gatek_gray-100 text-sm`}>Notre Equipe</button>
                    <button onClick={() => {navigate("./contact");}} className={`w-full truncate ${location.pathname.includes("contact") ? " Inter-Bold" : "Inter-Regular cursor-pointer  hover:opacity-70"} text-gatek_gray-100 text-sm`}>Contact</button>
                </div>
                <div className='relative w-[60%] h-full flex justify-end gap-4 items-center px-4'>
                    <button onClick={() => {navigate('/login')}}
                    className='
                        Poppins-SemiBold text-gatek_gray-100 bg-gatek_orange-500 px-8 rounded h-1/2
                        tranform duration-150 overflow-hidden text-sm
                        hover:scale-105
                    '>Se connecter</button>

                    <button onClick={() => {navigate('/register')}}
                    className='
                        Poppins-SemiBold text-gatek_gray-100 border border-gatek_orange-500 px-8 rounded h-1/2
                        tranform duration-150 overflow-hidden text-sm
                        hover:scale-105
                    '>S'enregistrer</button>

                    <button onClick={() => {handleDownload()}}
                    className='
                        Poppins-SemiBold text-gatek_gray-100 border border-gatek_orange-500 px-8 rounded h-1/2
                        tranform duration-150 overflow-hidden text-sm
                        hover:scale-105
                    '>Version mobile</button>

                    <div className='relative w-[20%]'><WatchDog /></div>
                </div>
            </div>
            <div className='relative w-full h-[95%] overflow-x-hidden'>
                <Outlet />
            </div>
        </div>

        //     <div className='relative w-[35%] h-[20%] flex items-center'>
        //         <img src={pandaroux_livre} className='relative z-50 h-full object-contain rounded-full' />
        //         <h1 className='relative self-start Poppins-SemiBold bg-white p-4 rounded-r-full rounded-t-full'>🎊 La bêta de la nouvelle version de Gatek est disponible !</h1>
        //     </div>

        //     <div className='relative w-full'>
        //         <div className='relative w-full flex px-16'>
        //             {tabs.map((option) => (
        //                 <div className={`relative w-1/4 h-10 rounded-tl rounded-tr shadow-2xl p-4 ${selectedTab === option ? 'bg-gatek_gray-100' : 'bg-gatek_gray-400'}`} onClick={() => setSelectedTab(option)}>
        //                     <div className='flex justify-center items-center h-full' onClick={() => setSelectedTab(option)}> {option} </div>
        //                 </div>
        //             ))}
        //         </div>
        //         {selectedTab == tabs[0] && <ProjectInformation/>}
        //         {selectedTab == tabs[1] && <Timeline/>}
        //         {selectedTab == tabs[2] && <Team/>}
        //         {selectedTab == tabs[3] && <Contact/>}
        //     </div>
        // </div>
    );
}

export default Home;