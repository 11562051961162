import React, {useContext, useEffect, useRef, useState} from 'react';
import test from '../../../Assets/Images/test.jpg';
import { Outlet, useNavigate } from 'react-router-dom';

import red_arrow from '../../../Assets/Images/red_arrow.png';
import unlike from '../../../Assets/Images/unlike_black.png';
import like from '../../../Assets/Images/like_black.png';
import comment from '../../../Assets/Images/comment.png';
import logo_gatek from '../../../Assets/Images/logo_gatek.png';
import add from '../../../Assets/Images/add.png';

import { MainContext } from '../../../Context/Main';
import { IArticle, IChapter, IDBChapter, IDiscover, IOeuvre } from '../../../Interfaces/Discover';
import { BuyChapter, getDiscoverd, getMangaInfo } from '../../../Api/Oeuvres';
import { backend_url } from '../../../App';
import OeuvreModal from './OeuvreModal';
import { GetArticles } from '../../Edit/Utils/IAdd';
import ArticleModal from './ArticleModal';
import { ITranslatedBook } from './Edit';
import { Textfit } from 'react-textfit';
import { SearchResponseType, UserInfo } from '../../../Api/Search';

export const handleImageError = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    // URL de votre image de remplacement
    e.currentTarget.src = logo_gatek;
};

const ObjectBox: React.FC<{label: string, grid?: number, setOpenSearch?: React.Dispatch<React.SetStateAction<boolean>>, oeuvres?: IOeuvre[], authors?: UserInfo[], teams?: UserInfo[], articles?: IArticle[], chapters?: IDBChapter[], traductions?:ITranslatedBook[], author?: boolean, white?: boolean, isCol?: boolean}> = (d) => {
    const {user, setuser, setcurrent_oeuvre, setcurrent_chapitre, setseedetails, setseearticle} = useContext(MainContext);
    const [grid, setgrid] = useState<number>(d.grid || 7);

    const navigate = useNavigate();

    const scrollContainer = useRef<HTMLDivElement>(null);
    
    const handleLeftClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (scrollContainer.current) {
            // scrollContainer.current.scrollLeft -= 200; // Ajustez la valeur selon le pas de défilement souhaité
            const step = 10;
            let i = 0;
            const interval = setInterval(() => {
                if (scrollContainer.current)
                    scrollContainer.current.scrollLeft -= step;
                i += step;
                if (i >= 200) {
                    clearInterval(interval);
                }
            }, 10);
        }
    };
    
    const handleRightClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();
        if (scrollContainer.current) {
            // scrollContainer.current.scrollLeft += 200; // Ajustez la valeur selon le pas de défilement souhaité
            // Ajustez la valeur selon le pas de défilement souhaité petit a petit comme une animation
            const step = 10;
            let i = 0;
            const interval = setInterval(() => {
                if (scrollContainer.current)
                    scrollContainer.current.scrollLeft += step;
                i += step;
                if (i >= 200) {
                    clearInterval(interval);
                }
            }, 10);
        }
    };

    return (
        <MainContext.Provider value={useContext(MainContext)}>
            <div className='relative w-full h-[90%] flex flex-col mt-8'>
                {!d.isCol && <h1 className={`Poppins-SemiBold text-3xl border-b-2 ${d.white ? "text-white" : " text-gatek_red-400 border-gatek_red-400"} `}>{d.label}</h1>}
                {d.oeuvres && d.chapters === undefined && <>
                    <div className='relative w-full h-full flex p-4 overflow-hidden bg-transparent'>
                        {d.oeuvres.length > 0 && <div onClick={handleLeftClick} className='absolute z-10 left-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        {d.oeuvres.length > 0 && <div onClick={handleRightClick} className='absolute z-10 right-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        <div ref={scrollContainer} className={'relative w-full h-full flex gap-8 overflow-hidden bg-transparent p-2'}>
                            {d.author && <div onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate('../add');
                            }} className={'flex-shrink-0 border-2 border-dashed flex items-center justify-center border-red-700 relative w-['+Math.ceil(100/grid)+'%] h-full pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'}>
                                <img src={add} alt={"Ajouter"} className='relative object-contain w-1/2 h-full'/>
                            </div>}
                            {d.oeuvres.length > 0 ? <>
                                {d.oeuvres.map((oeuvre, index) => {
                                    return (
                                        <div className={'flex-shrink-0 relative w-['+Math.ceil(100/grid)+'%] h-full flex flex-col pr-1 pb-1 transform hover:scale-[98%] transition duration-150 cursor-pointer'}>
                                            <img key={index} data-testid={"manga_"+index} src={backend_url+oeuvre.cover} alt={oeuvre.cover} onError={handleImageError} className='
                                                absolute w-full h-full
                                            ' onMouseOver={(e) => { 
                                            }} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();

                                                if (d.setOpenSearch)
                                                    d.setOpenSearch(false);

                                                if (d.author) {
                                                    sessionStorage.setItem('current_oeuvre_id', oeuvre.id);
                                                    navigate(`../editoeuvre/`);
                                                    // getMangaInfo(oeuvre).then((oeu) => {
                                                    //     setcurrent_oeuvre(oeu);
                                                    //     navigate(`../editoeuvre/`);
                                                    // });
                                                } else {
                                                    getMangaInfo(oeuvre).then((oeu) => {
                                                        setseedetails(oeu);
                                                    });
                                                }
                                            }} />
                                            <h1 className="absolute bottom-0 Poppins-Bold w-full text-white text-xl truncate p-2 bg-gradient-to-l from-transparent to-gatek_red-800">{oeuvre.name}</h1>
                                            {/* <p className='relative h-1/2'><img src={like} alt={"like"} className='h-3/4 object-contain'/>{}</p> */}
                                        </div>
                                    );
                                })}
                            </>
                            : <div className='relative w-full h-full flex justify-center items-center'>
                                <h1 className='Poppins-SemiBold text-xl text-gatek-400 italic'>Aucune oeuvre</h1>
                            </div>}
                        </div>
                    </div>
                </>}
                {d.articles && <>
                    <div className='relative w-full h-full flex p-4 overflow-hidden bg-transparent'>
                        {<div onClick={handleLeftClick} className='absolute z-10 left-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-black to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        {<div onClick={handleRightClick} className='absolute z-10 right-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-black to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        <div ref={scrollContainer} className='relative w-full h-full flex gap-8 overflow-hidden bg-transparent p-2'>
                            {d.author && <div onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                navigate('../addarticle');
                            }} className='flex-shrink-0 border-2 border-dashed flex items-center justify-center border-red-700 relative w-1/6 h-full pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                <img src={add} alt={"Ajouter"} className='relative object-contain w-1/2 h-full'/>
                            </div>}
                            {d.articles.length > 0 ? <>
                                {d.articles.map((article, index) => {
                                    return (
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (d.author) {
                                                sessionStorage.setItem('article_id', article.id);
                                                navigate('../modifyarticle');
                                            } else {
                                                setseearticle(article);
                                            }
                                        }} key={index} className='flex-shrink-0 relative w-1/6 h-full transform hover:scale-[98%] transition duration-150 cursor-pointer'>
                                            <img src={logo_gatek} onError={handleImageError} alt={article.title} className='
                                                relative w-full h-[40%] bg-black object-cover
                                            ' onMouseOver={(e) => {
                                            }} />
                                            <div className='relative h-[20%] w-full bg-gray-100 overflow-hidden'>
                                                <h1 className="relative h-[50%] Poppins-Bold w-full text-gatek_red-900 text-lg p-2 truncate"> {article.title}</h1>
                                                {/* <h1 className="relative h-[75%] Inter-Regular w-full text-gatek_red-900 text-xs p-0.5 overflow-hidden">{article.}</h1> */}
                                                <div className='relative h-[50%] w-full flex justify-between p-0.5'>
                                                    <div className='relative w-1/2 flex justify-start px-2 gap-2'>
                                                        <div className='relative h-full flex flex-col'>
                                                            <img src={like} alt={"like"} className='h-[50%] object-contain'/>
                                                            <p className='text-gatek_red-900 text-xs'> {article.likes}</p>
                                                        </div>
                                                        <div className='relative h-full flex flex-col'>
                                                            <img src={unlike} alt={"unlike"} className='h-[50%] object-contain'/>
                                                            <p className='text-gatek_red-900 text-xs'> {article.dislikes}</p>
                                                        </div>
                                                        <div className='relative h-full flex flex-col'>
                                                            <img src={comment} alt={"comment"} className='h-[50%] object-contain'/>
                                                            <p className='text-gatek_red-900 text-xs'> {article.nbComment}</p>
                                                        </div>
                                                    </div>
                                                    <h1 className="relative w-1/2 Inter-SemiBold italic text-gatek_red-900 text-xs text-right underline px-2"> En savoir plus</h1>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>: <div className='relative w-full h-full flex justify-center items-center'>
                                <h1 className='Poppins-SemiBold text-xl text-gatek-400 italic'>Aucun article</h1>
                            </div>}
                        </div>
                    </div>
                </>}
                {d.chapters && d.oeuvres && d.oeuvres[0] && !d.isCol ? <>
                    <div className='relative w-full h-full flex p-4 overflow-hidden bg-transparent'>
                        {d.chapters.length > 0 && <div onClick={handleLeftClick} className='absolute z-10 left-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        {d.chapters.length > 0 && <div onClick={handleRightClick} className='absolute z-10 right-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        <div ref={scrollContainer} className='relative w-full h-full flex gap-8 overflow-hidden bg-transparent p-2'>
                            {d.author && <div onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (d.oeuvres && d.oeuvres[0]) {
                                    sessionStorage.setItem('current_oeuvre_id', d.oeuvres[0].id);
                                    navigate('../addchapter');
                                }
                            }} className='flex-shrink-0 border-2 border-dashed flex items-center justify-center border-red-700 relative w-1/6 h-full pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                <img src={add} alt={"Ajouter"} className='relative object-contain w-1/2 h-full'/>
                            </div>} 
                            {d.chapters.length > 0 ? <>
                                {d.chapters.map((chapter, index) => {
                                    return (
                                        <div title={chapter.name} className='flex-shrink-0 relative w-1/6 h-full transform hover:scale-95 transition duration-150 cursor-pointer shadow-black shadow-md'>
                                            <img key={index} data-testid={"manga_"+index} src={backend_url+chapter.cover} alt={chapter.cover} onError={handleImageError} className='
                                                absolute w-full h-full
                                            ' onMouseOver={(e) => { 
                                            }} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (d.author && d.oeuvres && d.oeuvres[0]) {
                                                    sessionStorage.setItem('oeuvre_id', d.oeuvres[0].id);
                                                    sessionStorage.setItem('chapter_id', chapter.id);
                                                    navigate(`../modifychapter`);
                                                } else if (d.oeuvres && d.oeuvres[0]){
                                                    // setcurrent_oeuvre(d.oeuvres[0]);
                                                    // setcurrent_chapitre(chapter);
                                                    sessionStorage.setItem('oeuvres_id', d.oeuvres[0].id);
                                                    sessionStorage.setItem('chapter_id', chapter.id);
                                                    if (d.chapters && d.chapters[index+1]) sessionStorage.setItem('next_chapter_id', d.chapters[index+1].id); else sessionStorage.removeItem('next_chapter_id');
                                                    if (d.chapters && d.chapters[index-1]) sessionStorage.setItem('prev_chapter_id', d.chapters[index-1].id); else sessionStorage.removeItem('prev_chapter_id');
                                                    navigate(`./read`);
                                                    setseedetails(undefined);
                                                }
                                            }} />
                                            {/* <h1 className="absolute bottom-0 Poppins-SemiBold w-full h-[10%] text-white text-xs truncate p-2 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300 shadow-gray-600 shadow-md"> {chapter.name}</h1> */}
                                            <h1 className="absolute bottom-0 Inter-SemiBold w-full h-[10%] text-white text-xs truncate p-2 bg-gradient-to-l from-transparent to-gatek_red-800"> {chapter.name}</h1>
                                        </div>
                                    );
                                })}
                            </>
                            : <div className='relative w-full h-full flex justify-center items-center'>
                                <h1 className='Poppins-SemiBold text-xl text-gatek-400 italic'>Aucun chapitres</h1>
                            </div>}
                        </div>
                    </div>
                </> : d.chapters && <div className='w-full h-full flex flex-col gap-2 overflow-y-scroll custom-scrollbar'>
                    {d.chapters.length > 0 ? <>
                        {d.chapters.map((chapter, index) => {
                            return (
                                <div onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    if (!d.author && !chapter.chapterFree && !chapter.availability)
                                        return;

                                    if (d.oeuvres && d.oeuvres[0]){
                                        sessionStorage.setItem('oeuvres_id', d.oeuvres[0].id);
                                        sessionStorage.setItem('chapter_id', chapter.id);
                                        if (d.chapters && d.chapters[index+1]) sessionStorage.setItem('next_chapter_id', d.chapters[index+1].id); else sessionStorage.removeItem('next_chapter_id');
                                        if (d.chapters && d.chapters[index-1]) sessionStorage.setItem('prev_chapter_id', d.chapters[index-1].id); else sessionStorage.removeItem('prev_chapter_id');
                                        navigate(`./read`);
                                        setseedetails(undefined);
                                    }
                                }} title={chapter.name} className='relative w-full items-center h-[25%] border-b border-gray-400 flex gap-4 hover:opacity-60 duration-100 cursor-pointer'>
                                    {!d.author && !chapter.chapterFree && !chapter.availability &&
                                        <div className='absolute top-0 left-0 w-full z-50 h-full bg-black bg-opacity-80'>
                                            <h1 className='Poppins-SemiBold text-white text-2xl'>🔒</h1>
                                            <button className='absolute px-4 right-2 bottom-2 Poppins-SemiBold text-white bg-gatek-400 p-1 rounded-lg transform hover:scale-105' onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (d.oeuvres && d.oeuvres[0]){
                                                    BuyChapter(user?.id, d.oeuvres[0].id, chapter.id).then((res) => {
                                                        if (res && res.status === 200) {
                                                            alert("Achat effectué avec succès");
          
                                                            if (d.oeuvres && d.oeuvres[0]){
                                                                sessionStorage.setItem('oeuvres_id', d.oeuvres[0].id);
                                                                sessionStorage.setItem('chapter_id', chapter.id);
                                                                if (d.chapters && d.chapters[index+1]) sessionStorage.setItem('next_chapter_id', d.chapters[index+1].id); else sessionStorage.removeItem('next_chapter_id');
                                                                if (d.chapters && d.chapters[index-1]) sessionStorage.setItem('prev_chapter_id', d.chapters[index-1].id); else sessionStorage.removeItem('prev_chapter_id');
                                                                navigate(`./read`);
                                                                setseedetails(undefined);
                                                            }
                                                        } else {
                                                            alert("Erreur lors de l'achat");
                                                        }
                                                    });
                                                }
                                            }}>Acheter ( 1 coin )</button>
                                        </div>
                                    }
                                    <div className="relative w-[30%] h-full overflow-hidden">
                                        <img className='object-cover' key={index} data-testid={"chapter_"+index} src={backend_url+chapter.cover} alt={chapter.cover} onError={handleImageError} />
                                        <div className="absolute top-0 left-0 w-full h-full bg-gradient-to-r from-transparent via-transparent to-gatek_red-900 opacity-100"></div>
                                    </div>

                                    <div className='relative w-[70%] h-[80%]'>
                                        <h1 className='text-white Inter-SemiBold text-lg h-[20%] p-0.5'>{chapter.name}</h1>
                                        <h1 className='text-gray-300 Inter-regular text-xs h-[70%] line-clamp-6 p-0.5'></h1>
                                    </div>

                               </div>
                            );
                        })}
                    </>
                    : <div className='relative w-full h-full flex justify-center items-center'>
                        <h1 className='Poppins-SemiBold text-xl text-gatek-400 italic'>Aucun chapitres</h1>
                    </div>}
                </div>}
                {d.traductions && <>
                    <div className='relative w-full h-full flex p-4 overflow-hidden bg-transparent'>
                        {d.traductions.length > 0 && <div onClick={handleLeftClick} className='absolute z-10 left-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        {d.traductions.length > 0 && <div onClick={handleRightClick} className='absolute z-10 right-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        <div ref={scrollContainer} className='relative w-full h-full flex gap-8 overflow-hidden bg-transparent p-2'>
                            {d.traductions.length > 0 ? <>
                                {d.traductions.map((oeuvre, index) => {
                                    return (
                                        <div className='flex-shrink-0 relative w-1/6 h-full flex flex-col pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer'>
                                            <img key={index} data-testid={"manga_"+index} src={backend_url+oeuvre.mangaCover} alt={oeuvre.mangaCover} onError={handleImageError} className='
                                                absolute left-[10%] w-[90%] h-[80%]
                                            ' onMouseOver={(e) => { 
                                            }} onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                if (d.author) {
                                                    sessionStorage.setItem('translation_data', JSON.stringify(oeuvre));
                                                    navigate(`../validateTranslation`);
                                                }
                                            }} />
                                            <div className='absolute bottom-0 w-full h-[20%] text-white text-base truncate p-2 bg-gradient-to-r from-gatek_red-500 via-gatek_red-400 to-gatek_red-300 shadow-gray-600 shadow-md'>
                                                <h1 className="Poppins-SemiBold text-xl">📕 {oeuvre.mangaName}</h1>
                                                <h1 className="Poppins-SemiBold text-base">📄 {oeuvre.chapterName}</h1>
                                                <h1 className="Inter-Regular text-sm">🌍 {oeuvre.language}</h1>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                            : <div className='relative w-full h-full flex justify-center items-center'>
                                <h1 className='Poppins-SemiBold text-xl text-gatek-400 italic'>Aucune traductions en attente</h1>
                            </div>}
                        </div>
                    </div>
                </>}

                {d.authors && <>
                    <div className='relative w-full h-full flex p-4 overflow-hidden bg-transparent'>
                        {d.authors.length > 0 && <div onClick={handleLeftClick} className='absolute z-10 left-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        {d.authors.length > 0 && <div onClick={handleRightClick} className='absolute z-10 right-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        <div ref={scrollContainer} className={'relative w-full h-full flex gap-4 overflow-hidden bg-transparent p-2'}>
                            {d.authors.length > 0 ? <>
                                {d.authors.map((author, index) => {
                                    return (
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (d.setOpenSearch)
                                                d.setOpenSearch(false);
                                            sessionStorage.setItem('author_id', author.id);
                                            navigate('./author');
                                        }} className={'flex-shrink-0 relative w-['+Math.ceil(100/grid)+'%] h-full flex flex-col pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer shadow-black shadow-md'}>
                                            <img key={index} data-testid={"manga_"+index} src={backend_url+author.icon} alt={author.icon} onError={handleImageError} className='
                                                relative w-full h-full
                                            ' onMouseOver={(e) => { 
                                            }} />
                                            <div className='absolute bottom-0 w-full h-[30%] text-white text-base truncate p-2 bg-gradient-to-l from-transparent to-gatek_red-800'>
                                                <h1 className="Inter-Bold text-xl truncate">{author.name}</h1>
                                                <h1 className="Inter-Regular text-sm truncate">Auteur</h1>
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                            : <div className='relative w-full h-full flex justify-center items-center'>
                                <h1 className='Poppins-SemiBold text-xl text-gatek-400 italic'>Aucune traductions en attente</h1>
                            </div>}
                        </div>
                    </div>
                </>}

                {d.teams && <>
                    <div className='relative w-full h-full flex p-4 overflow-hidden bg-transparent'>
                        {d.teams.length > 0 && <div onClick={handleLeftClick} className='absolute z-10 left-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform rotate-180'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        {d.teams.length > 0 && <div onClick={handleRightClick} className='absolute z-10 right-0 top-0 h-full w-14 opacity-0 transition-all duration-300
                        hover:bg-gray-900 to-transparent hover:opacity-80 cursor-pointer flex items-center justify-center transform'>
                            <img src={red_arrow} className='w-1/2 object-contain' />
                        </div>}
                        <div ref={scrollContainer} className={'relative w-full h-full flex gap-4 overflow-hidden bg-transparent p-2'}>
                            {d.teams.length > 0 ? <>
                                {d.teams.map((team, index) => {
                                    return (
                                        <div onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            if (d.setOpenSearch)
                                                d.setOpenSearch(false);
                                            sessionStorage.setItem('team_id', team.id);
                                            navigate('./team');
                                        }} className={'flex-shrink-0 relative w-['+Math.ceil(100/grid)+'%] h-full flex flex-col pr-1 pb-1 transform hover:scale-95 transition duration-150 cursor-pointer shadow-black shadow-md'}>
                                            <img key={index} data-testid={"manga_"+index} src={backend_url+team.icon} alt={team.icon} onError={handleImageError} className='
                                                relative w-full h-full
                                            ' onMouseOver={(e) => { 
                                            }} />
                                            <div className='absolute bottom-0 w-full h-[30%] text-white text-base truncate p-2 bg-gradient-to-l from-transparent to-gatek_red-800'>
                                                <h1 className="Inter-Bold text-xl truncate">{team.name}</h1>
                                                {/* <h1 className="Inter-Regular text-sm truncate">Auteur</h1> */}
                                            </div>
                                        </div>
                                    );
                                })}
                            </>
                            : <div className='relative w-full h-full flex justify-center items-center'>
                                <h1 className='Poppins-SemiBold text-xl text-gatek-400 italic'>Aucune traductions en attente</h1>
                            </div>}
                        </div>
                    </div>
                </>}
            </div>
        </MainContext.Provider>
    );
}

export default ObjectBox;